import Image from 'next/image';
import { Box, Container, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2, is actually stable and part of MUI6

import { track } from 'lib/tracking/helper';
import { TrackingEvents } from 'lib/tracking/events';
import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';
import { useDecision } from 'lib/optimizely';
/*
 * This is a prototype component that we use to link in an uneditable
 * component from contentful. Note that the name is used for linking,
 * so it cannot be renamed.
 */
export const BankingPartnersBanner = () => {
  const logoWidth = 140;
  const logoHeight = 50;

  const [decision] = useDecision(ABTestKeys.CAT_3290_best_offer_box_removal_AB);
  const variation = decision?.variationKey || Variation.CONTROL;
  const showDefaultVariant = variation !== Variation.VARIATION_B; // key could be "off"

  return (
    <Container maxWidth="lg" sx={{ marginTop: '16px', marginBottom: '32px' }}>
      <Box boxShadow={3} sx={{ borderRadius: '8px', padding: '8px 16px' }}>
        <Typography variant="h4" align="center">
          Wir vergleichen über 60 Kreditangebote und finden für Sie das passende
          Angebot
        </Typography>
        <Typography variant="body1" fontSize={18} align="center">
          Eine Auswahl unserer Partner:
        </Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/6ZWUORwxPEgmApUdz6Ozxc/ecac086d1282d333acfa64448a2f338f/postbank.svg"
              alt="Postbank Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/6h5D1j6rk8PmMDFziIqvyq/56d2f1a0a26ee531f1134157eaa41bbd/ing.svg"
              alt="ING Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/6oTPlh6dvPEC4iBrxBTtbf/6bc723317dee3cc8ab16dea309ebda38/santander.svg"
              alt="Santander Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/3fWVvapy5uAZuwruWIs5IH/7a4f980a784f3fc88362f00dd1e10af0/vvrb-logo.svg"
              alt="Vereinigte Volksbank Raiffeisenbank Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/xgUiznBcP9frO95fEezhl/3d498feb684f5c0f306f15ac7222c73b/targobank.svg"
              alt="Targo Bank Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/3hK1no0DyvUUv2bCyCUQTA/1bd9501ed27923c33658118ac7f2b837/ofina.svg"
              alt="Ofina Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
          <Grid>
            <Image
              src="https://images.ctfassets.net/f6c1mtmq4qrb/5EhclUFoPZ9qRUpMn1Djf0/c723271db009a225161069d2da1a0585/bankofscotland.svg"
              alt="Bank of Scotland Logo"
              width={logoWidth}
              height={logoHeight}
              priority={false}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="body1"
        mt={1}
        mb={4}
        onClick={() => {
          track(TrackingEvents.BANK_LIST_LINK_CLICKED);
        }}
      >
        <Link
          href="/kreditinstitut/"
          sx={{
            fontSize: '12px',
            color: '#323232',
            textDecoration: 'underline',
          }}
          target="_blank"
        >
          {showDefaultVariant
            ? 'Teilnehmende Kreditbanken/Vermittler'
            : 'Kooperiende Partnerbanken/Kreditvergabepartner'}
        </Link>
      </Typography>
    </Container>
  );
};
