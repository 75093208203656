import { Container, Grid, Typography, styled } from '@mui/material';

import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';
import { useDecision } from 'lib/optimizely';

import { useBestOffer } from 'contexts/BestOfferContext';
import { useLoanSelection } from 'contexts/LoanSelectionContext';
import { getTwoThirdsLegalText } from 'lib/legaltext/legalTextHelper';

/*
 * This is a prototype component that we use to link in an uneditable
 * component from contentful. Note that the name is used for linking,
 * so it cannot be renamed.
 */
interface LegalTextProps {
  'padding-style': string;
}

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isHomePadding',
})<{ isHomePadding: boolean }>(({ isHomePadding }) => ({
  marginTop: '16px',
  marginBottom: '24px',
  '@media (min-width: 1200px)': {
    paddingLeft: isHomePadding ? '0px !important' : 'auto',
  },
}));

export const LegalTextLoanRangesGoogle = (props: LegalTextProps) => {
  const isHomePadding = props['padding-style'] === 'home';

  const [decision] = useDecision(ABTestKeys.CAT_3290_best_offer_box_removal_AB);
  const variation = decision?.variationKey || Variation.CONTROL;
  const showDefaultVariant = variation !== Variation.VARIATION_B; // key could be "off"
  const showExtendedVariant = variation === Variation.VARIATION_B;

  const { loanSelection } = useLoanSelection();
  const { bestOffer } = useBestOffer();

  const legelOfferText = getTwoThirdsLegalText(bestOffer, loanSelection);

  return (
    <StyledContainer maxWidth="lg" isHomePadding={isHomePadding}>
      {showDefaultVariant && (
        <>
          <Typography variant="h5" mb={2}>
            Ratenkredit Konditionen Übersicht
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: '12px', lineHeight: '20px' }}
          >
            <b>Zinssätze:</b> Min. -0,40 % bis max. 19,90 % effektiver
            Jahreszins
            <br />
            <b>Laufzeit:</b> Min. 6 bis Max. 120 Monate
            <br />
            <b>Nettodarlehensbetrag</b> von 1.000 € bis 150.000 €<br />
            <b>Gesamtbetrag</b> von 995,50 € bis 181.247,51 €
          </Typography>
        </>
      )}

      {showExtendedVariant && (
        <>
          <Typography variant="h5" mb={2}>
            Übersicht der möglichen Kreditkonditionen
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                sx={{ fontSize: '12px', lineHeight: '20px' }}
              >
                <b>Jährliche Zinssätze:</b> min. -0,40 % bis max. 19,90 %
                effektiver Jahreszins
                <br />
                <b>Laufzeit:</b> min. 6 bis max. 120 Monate
                <br />
                <b>Nettokreditbetrag</b> ab 1.000 € bis 150.000 €<br />
                <b>Gesamtbetrag</b> ab 995,50 € bis 181.247,51 €
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                sx={{ fontSize: '12px', lineHeight: '20px' }}
              >
                <b>Beispielrechnung gemäß PAngV</b> {legelOfferText}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </StyledContainer>
  );
};
